<template>
  <component :is="currentComponent"></component>
</template>

<script>
import { getPropTradingComponent } from '@/util/getFileComponent';

export default {
  name: 'PropTradingChildView',
  computed: {
    routerName() {
      let name = this.$route.name.split('propTrading')[1];
      return `${name.charAt(0).toLowerCase()}${name.slice(1)}`;
    },
    currentComponent() {
      return getPropTradingComponent(this.routerName, this.regulator);
    }
  }
};
</script>
