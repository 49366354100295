import { isRegisterFileExist, isPropTradingFileExist } from '@/util/checkFileExist'

function getRegisterComponent(pathName, regulator) {
  let regulatorFileName = regulator.toUpperCase()
  let fileName = isRegisterFileExist(regulatorFileName, pathName) ? regulatorFileName : 'Default'
  return () => import(`@/components/register/${pathName}/${fileName}`)
}

function getPropTradingComponent(pathName, regulator) {
  let regulatorFileName = regulator.toUpperCase()
  let fileName = isPropTradingFileExist(regulatorFileName, pathName) ? regulatorFileName : 'Default'
  return () => import(`@/components/propTrading/${pathName}/${fileName}`)
}

export { getRegisterComponent, getPropTradingComponent }
